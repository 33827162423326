<template>
    <div class='container'>

        <div class="box">

            <h3>你好，【{{ name }}】</h3>

            <div class="news">
                第一个<strong>90</strong>天，让我们给您准备这些<br>
                每天一小包，让健康方便简单
            </div>

            <van-image
                    width="100%"
                    :src="values.show_pic"
                    v-for="(values) in data"
                    :key="values.id"
                    @click="href(values.id)"/>

        </div>

        <div class="boxgg">
            <p>我们将尽快准备好并通过海外直邮发货<br>
                预计<span class="unmber7">7</span>个工作日到您手中
            </p>

            <div class="haha">
                如有任何问题请联系您的营养顾问
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        // Vue 实例的数据对象
        data() {
            return {
                data: [],
                name:''
            }
        },
        created() {
            this.getData()
            this.name = window.localStorage.getItem('name')
        },
        methods: {
            getData() {
                let toast = this.$toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    overlay:true
                });
                let query = this.$route.params
                this.$request.get("nutritions", {
                    order_sn: query.sn,
                    nutrition_id: query.id
                }).then(res => {
                    if (res.code == 1) {
                        this.data = res.data
                        toast.clear()
                    }
                    console.log(res)
                })
            },
            href(id) {
                this.$router.push({
                    path: `/product/detail/${id}`
                })
            }
        }
    }
</script>

<style lang='less' scoped>
    .container {
        background: #f3f3f4;
        min-height: 100vh;
        font-size: 14px;
        color: #292c2f;
        padding: 14.5px 10px 0px 10px;

        .box {
            background-color: #fefefe;
            padding: 20px 10px 1px 10px;
            margin-bottom: 15px;
            border-radius: 10px;

            h3 {
                display: inline-block;
                font-size: 21px;
               font-weight: 700;
                padding-bottom: 6px;
                border-bottom: 4px solid #292c2f;
            }

            .news {
                padding-top: 20px;
                padding-bottom: 25px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 1px;
            }

            .icon {
                display: flex;
                align-items: center;
                font-size: 16px;
                margin-bottom: 15px;

                .iconimg {
                    width: 22.5px;
                    height: 23.5px;
                    margin-right: 6.5px;
                }
            }

            .hashagei {
                font-size: 17px;
                margin-bottom: 20px;
            }
        }

        .boxgg {
            font-size: 17px;
            font-weight: 700;
            .unmber7 {
                color: #FE4B12;
                display: inline-block;
                font-size: 24px;
                text-indent: 4px;
                letter-spacing: 4px;
            }

            .haha {
                padding-top: 10px;
                padding-bottom: 35px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
        }

        /deep/ .van-image {
            margin-bottom: 20px;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }
</style>
